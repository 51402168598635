.data-stack-vehiculo{
    position: absolute;
    width: 100%;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.grid-stack-item-content{
    .list-group-item{
        padding: 0px;
    }
}

.table-vehiculos-row-descripcion{
    font-size: small;
    div{
        font-weight: bold;
    }
}

.grid-stack-stack-jugador .grid-stack-item-jugador .grid-stack-item-content{
    position: revert;
    width: 100%;
    height: 40px;
}

.grid-stack-item-nombre{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size:15px;
    text-align:center;
    font-weight: bold;
    width: 100% !important;
}

.grid-stack .grid-stack-item:has(.grid-stack-placeholder){
    background-color: red;
}


// div#draggableGrid:active {
//     border: solid red !important;
//     border-top-left-radius: 5px !important;
//     border-top-right-radius: 5px !important;
//     border-bottom-right-radius: 5px !important;
//     border-bottom-left-radius: 5px !important;
// }

.list-group-item{
    padding:1px;
}
.list-group-item .list-group-item-title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table-flotas-row-seleccionado:hover + .grid-stack-item-seleccionado{
    box-shadow: 0px 0px 0px 3px #FC5185;
}
.table-flotas-row-seleccionado .list-group{
    opacity: 0.3;
}
.table-vehiculos-row-seleccionado{
    opacity: 0.3;
}
.table-jugador-row-seleccionado{
    opacity: 0.3;
}
.table-vehiculos-row-seleccionado:hover + .grid-stack-item-seleccionado{
    box-shadow: 0px 0px 0px 3px #FC5185;
}

.table-vehiculos-row-patente{
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.table-vehiculos-row-patente .grid-stack-item-nombre{
    cursor: pointer;
}
/*
.table-jugadores-row-nombre.list-group{
    margin-left: 4rem;
    margin-right: 4rem;
}
*/
.table-jugadores-row-nombre.list-group .list-group-item-title{
    text-align: center;
}

.table-jugadores-row-nombre .list-group-item-title{
    cursor: pointer;
}
.grid-stack.top .list-group:empty{
    min-height: 31px;
    margin: 2px;
    border-color: white;
    border-style: dashed;
    border-width: thin;
}

.list-group-item-subtitle{
    font-size: small;
}

.grid-stack-item-nombre {
    border-radius: 8px !important;
    color: #fff;
    background-color: #354A53;
    border-color: #354A53;
    box-shadow: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}