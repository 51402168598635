
.default-invalid-feedback{
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.is-invalid{
    .react-select__control{
        border-color: #dc3545;
    }
}

div.react-datepicker-popper {
    z-index: 1000;
}
.react-select-container {
    svg{
        height: 1rem;
        width: 1rem;
    }
}