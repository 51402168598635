@import url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/5.5.1/collection/components/icon/icon.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap');


@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';

@import './themes/admin-lte.scss';
@import '~overlayscrollbars/css/OverlayScrollbars.min.css';

@import '~react-toastify/scss/main';
@import '~gridstack/dist/gridstack.min.css';
@import "~react-datepicker/src/stylesheets/datepicker.scss";
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "~react-photo-view/dist/index.css";

#root {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
    background-color: #f4f6f9;
}

.PhotoView__PhotoBox{
    width: auto !important;
    height: auto !important;
}

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// Sidebar / Background en lista

[class*=sidebar-dark-] .nav-sidebar > .nav-item > .nav-treeview {
    background-color: #22222b;
    border-radius: 6px;
}

// Tablas fondo blanco

.table{
    background-color: white;
}

.btn{
    border-radius: 4px!important;
}

.card{
    border-radius: 4px !important;
}

label.btn.active.btn-secondary.btn-sm {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

label#labelcoberturaMb {
    margin-top: 5px;
}

.btn{
    margin-left: 1px !important;
    margin-right: 1px !important;
    border-radius: 8px !important;

}
.btn-primary{
   color:white;
   
}

.t-white{
    color:white !important;
    
}

.card{
    border-radius: 0.5rem !important;
}

.calendar-week-day-title{
    
}


.fila-desaparecer:hover .boton-desaparecer{
    position: absolute;
    display: inline-block;
}

.boton-desaparecer{
    display: none;
}

table{
    text-align:center;
    border: 1px solid #dee2e6 !important;
    font-size: 0.875rem;
}

input.form-control {
    border-radius: 4px;
    height: 80%;
}

.redondeado{
    border-radius: 0.5rem !important;
}


.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
    color: #ffffff;
}

.flip {
    -webkit-perspective: 800;   
            perspective: 800;
          position: relative;
          text-align: center;
  }
  .flip .card.flipped {
    -webkit-transform: rotatey(-180deg);
            transform: rotatey(-180deg);
  }
  .flip .card {
      width: 270px;
      height: 178px;
      -webkit-transform-style: preserve-3d;
      -webkit-transition: 0.5s;
      transform-style: preserve-3d;
      transition: 0.5s;
      background-color: #fff;
     
  }
  .flip .card .face {
    -webkit-backface-visibility: hidden ;
      backface-visibility: hidden ;
     z-index: 2;
  }
  .flip .card .front {
     position: absolute;
     width: 270px;
     z-index: 1;
  }
  .flip .card .front img{
    width: 270px;
    height: 100%;
  }
  .flip .card .img {
     position: relaitve;
     width: 270px;
     height: 178px;
     z-index: 1;
     border: 2px solid #000;
  }
  .flip .card .back {
    padding-top: 10%;
    -webkit-transform: rotatey(-180deg);
            transform: rotatey(-180deg);
    position: absolute;
  }
  .inner{
    margin:0px !important;
    width: 270px;
  }

  .btn-jugadores{
    color: #fff;
    background-color: #98AFBA;
    border-color: #98AFBA;
    box-shadow: none;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#draggableGrid:has(.grid-on-drag){
    border:solid red !important;
}

.asignacion .grid-stack{
    background-color: white;
    border-radius: 0.25rem !important;
    border: 1px solid #dee2e6 !important;
}


.container-fluid :active .grid-stack {
    box-shadow: 0px 0px 121px #A9BDD6 inset;
}

.container-fluid .grid-stack {
    border-radius: 4px;
    width: 101.7%;
    transition: 0.5s;
    margin-left: -7px;
}

.ihTkSR .carousel-panel {
    position: absolute;
    bottom: 27px !important;
    width: 96% !important;
    z-index: 10;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 10px;
}


.grid-stack-item-content{
    border-radius: 0.3rem !important;
    text-align: center !important;
    background-color: #39C0ED;

}
.grid-stack-item-content.grid-stack-item-seleccionado{
    opacity: 0.1;
}

.data-stack-vehiculo{
    color: white;
}

.list-group-item-subtitle{

    color: gray;
}

.rdw-option-wrapper {
    padding: 2px;
}

.input-range__track--active {
    background: #39C0ED;
}

.input-range__slider {
    background: #39C0ED;
    border: 1px solid white;
}

.react-datepicker-wrapper {
    display: block;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;

}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 100;
}

.login-page, .register-page {

    background-color: #0f98c5 !important;
}

.sc-gsDJrp.goqHif {
    text-align: center;
    border: solid #39c0ed 1px;
    border-radius: 8px;
}

.calendar-event-tareas:before {
    z-index:4;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.2;
    background-repeat: repeat;
    background-position: 50% 0;
  }

  .t-white{
      color: white !important;
  }

  .btn-mostrar-pass{
    border-radius: 3px !important;
  }

  .btn-regresar{
    border-radius: 50% !important;
  }

  .img-seleccionar:hover{
    filter: grayscale(80%) !important;
  }

  p.descripcion {
    font-size: 1rem;
    text-align: center;
    margin-top: 200px;
    transition: margin-top 0.4s;
  }

  div.img-seleccionar:hover p.descripcion {
    margin-top: 75px;
  }

  .filtros-tablas{
      border:none !important;
  }

  .text-decoration-none{
      text-decoration: none;
      color: white;
  }

  .btn-redondo{
      border-radius: 50% !important;
  }

  .btn-cuadrado{
    border-radius: 0% !important;
  }
