.fc {
    min-height: 70vh;
}

.fc-timegrid{
    background-color: white !important;
}

.fc-dayGridMonth-view{
    background-color: white !important;

}

.fc-daygrid-day-number{
    color:  #3c4043;
}
.fc-daygrid-day-number:hover{
    color:  #3c4043;
}

.calendar-week-day-title{
    color:  #3c4043;
    
}

.mes{
    color:  #3c4043;
    
}


.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    text-transform: uppercase;
}

.ciudad{
    overflow: hidden;
}

.ciudadDiv{
    overflow: hidden;
}


.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    display: none !important;
}

@keyframes scroll-left {
    from {margin-left: 0;}
    to {margin-left: -200%;}
  }
  a.fc-col-header-cell-cushion {
    width: 100% !important;
}
  .transicion:hover {
    animation: scroll-left 4s ease-in-out infinite alternate;
    overflow: hidden;
  }

  table.fc-scrollgrid.fc-scrollgrid-liquid {
    border: 0 !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    border-color: #fff !important;
    border: 1px solid #dee2e6 !important;

}

.fc-media-screen .fc-timegrid-event-harness {
    margin: 0 !important;
}


.fc-media-screen .fc-timegrid-event {
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 0;
    right: 0;
    overflow: hidden;
}



//   .fc-timeGridDay-view .fc-content-skeleton table tbody :first-child .calendar-event-cuadrillaDisponibleLugar::before{
//     content: "TURNO MAÑANA";
//     text-align: center;
//     padding-left: 50px;
//     padding-right: 50px;
//     position: absolute;
//     top: -20px;
//     left: 0px;
//     margin-left: 20vh;
//     height: 19px;
//     width: 125px;
//     border-top-left-radius: 25px;
//     border-top-right-radius: 25px;
//     background-color: #3788D8;
//     color: white;
//     box-shadow: 0 -5px 0 0 #DBF2FA;
//     z-index: 20;
//   }
//   .fc-timeGridDay-view .fc-content-skeleton table tbody :first-child .calendar-event-cuadrillaDisponibleLugar::after{
//     content: "TURNO TARDE";
//     text-align: center;
//     padding-left: 50px;
//     padding-right: 50px;
//     position: absolute;
//     top: -20px;
//     right: 0px;
//     margin-right: 20vh;
//     height: 19px;
//     width: 125px;
//     border-top-left-radius: 25px;
//     border-top-right-radius: 25px;
//     background-color: #3788D8;
//     color: white;
//     box-shadow: 0 -5px 0 0 #DBF2FA;
//     z-index: 20;
//   }
