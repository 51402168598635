.redondeado-arriba {
    border-top-left-radius: 1.25rem!important;
    border-top-right-radius: 1.25rem!important;
}

.redondeo {
    border-radius: 1.0rem!important;
}

.bg-transparente{
    background-color: #eceff6 !important;
}

.img-rounded {
    border-radius: 0.5rem!important;
}

.checkbox-lg .custom-control-label::before, 
.checkbox-lg .custom-control-label::after {
  top: .8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 13px;
  padding-left: 6px;
}


.checkbox-xl .custom-control-label::before, 
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

.target{
    border-radius: 0.8rem!important;

}
