.imagen-no-seleccionada{
    filter: blur(0.3px); 
    opacity: 0.6;
}
.imagen-seleccionada{
    transform:scale(1.1);
}

img{
    width: 100%;
    height: 100%;
}

// .imagen-NoDisponible{
//     filter: grayscale(80%);
// }


